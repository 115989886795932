import { environment } from 'src/environments/environment';

export const API_URL = environment.apiUrl;
export const APP_URL = environment.appBaseUrl;
export const SUPPORT_EMAIL = environment.supportEmail;
export const VALID_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'csv'];
export const MAX_UPLOAD_SIZE = 10485760; //10MB in bytes
export const REGEX_TAGS = new RegExp(/^[a-zA-Z0-9]+(?:,[a-zA-Z0-9]+)*$/);
export const NORM_GRAPH_XAXIS_LABELS = [
  '',
  '',
  '',
  '0%',
  '10%',
  '20%',
  '30%',
  '40%',
  '50%',
  '60%',
  '70%',
  '80%',
  '90%',
  '100%',
  '',
  '',
  ''
];
export const NORM_GRAPH_BORDER_COLOR = 'rgb(0, 0, 0, 0.5)';
export const NORM_GRAPH_FILL_COLOR = 'rgb(60, 95, 166)';
export const GRAPH_NO_COLOR = 'rgb(0,0,0,0)';
export const GRAPH_GRIDLINE_COLOR = '#000';
export const PERSONALITY_GRAPH_POSITIVE_COLOR = 'green';
export const PERSONALITY_GRAPH_NEGATIVE_COLOR = 'orange';
export const TRANSLATED_LANGUAGES = [
  { name: 'English', code: 'en', flag: 'us-flag' },
  { name: 'Swedish', code: 'sv', flag: 'sweden-flag' },
  { name: 'Finnish', code: 'fi', flag: 'finnish-flag' },
  { name: 'Danish', code: 'da', flag: 'danish-flag' },
  { name: 'Norwegian', code: 'no', flag: 'norwegian-flag' },
  { name: 'Spanish', code: 'es', flag: 'spanish-flag' }
  // { name: 'Dutch', code: 'nl', flag: 'dutch-flag' }
];

export const STORE_MANAGEMENT_KEY = 'store-management';
export const CHRX = atob('cGFzc3dvcmQ=');
