import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, inject } from '@angular/core';
import { FetchArgs, fetchBaseQuery, retry } from 'ngrx-rtk-query';
import Session from 'store-management';
import * as Sentry from '@sentry/angular-ivy';

import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { API_URL, STORE_MANAGEMENT_KEY } from 'src/app/constants';
import { KeyType } from 'src/app/shared/types';
import { ErrorStatuses } from 'src/app/shared/types/enums';
import { I18nService } from '../../../shared/services/i18n/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

const oneSecBackoff = async (attempt = 0, maxRetries = 5) => {
  await new Promise((resolve) => {
    setTimeout(resolve, 1000);
  });
};

const session: Session = new Session();
const httpClientBaseQuery = retry(
  fetchBaseQuery(
    (
      http = inject(HttpClient),
      enviroment = inject(ENVIRONMENT_INITIALIZER)
    ) => {
      const router: Router = inject(Router);
      const i18nService: I18nService = inject(I18nService);
      const translate: TranslateService = inject(TranslateService);
      const toastr: ToastrService = inject(ToastrService);
      const state: ActivatedRoute = inject(ActivatedRoute);
      return async (args, { signal }) => {
        const {
          url,
          method = 'get',
          authorizationRequired = true,
          isFormData = false,
          body = undefined,
          params = undefined
        } = args as FetchArgs & {
          authorizationRequired: boolean;
          isFormData: boolean;
        };
        const apiUrl = `${API_URL}${url}`;

        const headersObject: KeyType = {};
        if (!isFormData) {
          headersObject['Accept'] = 'application/json';
          headersObject['Content-Type'] = 'application/json';
        }
        headersObject['Authorization'] = `Bearer ${
          session.get('user')?.access_token
        }`;

        const headers = new HttpHeaders(headersObject as unknown as any);

        const request$ = http.request(method, apiUrl, {
          body,
          params,
          ...(authorizationRequired ? { headers } : {})
        });
        try {
          const data = await lastValueFrom(request$);
          return { data };
        } catch (err: any) {
          const response = err as HttpErrorResponse;

          if (response.status !== 451 && response.status !== 400) {
            Sentry.captureException(err);
          }
          const toasterTimeOut = 5000; // 5 seconds

          // Session ended
          if (response.status === 451) {
            retry.fail(response.error);
          }

          // Server Error
          if (response.status === 500) {
            const toastRef = toastr.error(
              translate.instant('error.server'),
              '',
              {
                positionClass: 'toast-bottom-center',
                timeOut: toasterTimeOut,
                closeButton: true
              }
            );
            toastRef.onTap.subscribe(() => {
              window.open(
                'https://cohrex.zammad.com/help/en-us/1-technical-issues/2-i-receive-an-error-in-the-cohrex-platform',
                '_blank'
              );
            });
          }

          // Unauthorized error
          if (response.status === 403) {
            const toastRef = toastr.error(
              translate.instant('error.unauthorized'),
              '',
              {
                positionClass: 'toast-bottom-center',
                timeOut: toasterTimeOut,
                closeButton: true
              }
            );

            toastRef.onTap.subscribe(() => {
              window.open(
                'https://cohrex.zammad.com/help/en-us/1-technical-issues/2-i-receive-an-error-in-the-cohrex-platform',
                '_blank'
              );
            });
            retry.fail(response.error);
          }

          // Connection Failed
          if (
            err.message.includes('Connection refused') ||
            response.status === 0
          ) {
            const connectionFailedToasterRef = toastr.error(
              translate.instant('error.connection_failed'),
              '',
              {
                positionClass: 'toast-bottom-center',
                timeOut: toasterTimeOut,
                closeButton: true
              }
            );

            connectionFailedToasterRef.onTap.subscribe(() => {
              window.open(
                'https://cohrex.zammad.com/help/en-us/1-technical-issues/1-connection-failed-or-was-blocked',
                '_blank'
              );
            });
          }

          // Generic errors
          if (
            response.status === 429 ||
            (response.status >= 501 && response.status < 600) ||
            response.status === 404
          ) {
            const toastRef = toastr.error(
              translate.instant('error.generic'),
              '',
              {
                positionClass: 'toast-bottom-center',
                timeOut: toasterTimeOut,
                closeButton: true
              }
            );

            toastRef.onTap.subscribe(() => {
              window.open(
                'https://cohrex.zammad.com/help/en-us/1-technical-issues/2-i-receive-an-error-in-the-cohrex-platform',
                '_blank'
              );
            });
          }

          if (response.status === ErrorStatuses.Unauthorized) {
            localStorage.removeItem(STORE_MANAGEMENT_KEY);
            router.navigate(['/']);
          }
          return {
            error: {
              status: response?.status,
              data: response?.message,
              message: response?.error?.message
            }
          };
        }
      };
    }
  ),
  { maxRetries: 3 }
);

export default httpClientBaseQuery;
